var $CLJS = module.exports = {};
/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
'use strict';var ba,da,ea,fa,ia,ja;ba=function(){var a=$CLJS.aa.navigator;return a&&(a=a.userAgent)?a:""};da=function(a){return-1!=ba().indexOf(a)};ea=function(){return da("Firefox")||da("FxiOS")};fa=function(){return(da("Chrome")||da("CriOS"))&&!da("Edge")||da("Silk")};
$CLJS.ha=function(a,b){const c=Array.prototype.slice.call(arguments),d=c.shift();if("undefined"==typeof d)throw Error("[goog.string.format] Template required");return d.replace(/%([0\- \+]*)(\d+)?(\.(\d+))?([%sfdiu])/g,function(e,f,h,l,m,t,u,v){if("%"==t)return"%";const x=c.shift();if("undefined"==typeof x)throw Error("[goog.string.format] Not enough arguments");arguments[0]=x;return $CLJS.ha.vc[t].apply(null,arguments)})};ia=function(){return da("iPhone")&&!da("iPod")&&!da("iPad")};
ja=function(){ia()||da("iPad")||da("iPod")};$CLJS.ka=$CLJS.ka||{};$CLJS.aa=global;$CLJS.la="closure_uid_"+(1E9*Math.random()>>>0);$CLJS.aa=global;$CLJS.ma=String.prototype.trim?function(a){return a.trim()}:function(a){return/^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};var na=String.prototype.repeat?function(a,b){return a.repeat(b)}:function(a,b){return Array(b+1).join(a)};$CLJS.oa=class{constructor(a,b){this.name=a;this.value=b}toString(){return this.name}};$CLJS.pa=new $CLJS.oa("OFF",Infinity);$CLJS.qa=[$CLJS.pa,new $CLJS.oa("SHOUT",1200),new $CLJS.oa("SEVERE",1E3),new $CLJS.oa("WARNING",900),new $CLJS.oa("INFO",800),new $CLJS.oa("CONFIG",700),new $CLJS.oa("FINE",500),new $CLJS.oa("FINER",400),new $CLJS.oa("FINEST",300),new $CLJS.oa("ALL",0)];$CLJS.ha.vc={};$CLJS.ha.vc.s=function(a,b,c){return isNaN(c)||""==c||a.length>=Number(c)?a:a=-1<b.indexOf("-",0)?a+na(" ",Number(c)-a.length):na(" ",Number(c)-a.length)+a};
$CLJS.ha.vc.f=function(a,b,c,d,e){d=a.toString();isNaN(e)||""==e||(d=parseFloat(a).toFixed(e));let f;f=0>Number(a)?"-":0<=b.indexOf("+")?"+":0<=b.indexOf(" ")?" ":"";0<=Number(a)&&(d=f+d);if(isNaN(c)||d.length>=Number(c))return d;d=isNaN(e)?Math.abs(Number(a)).toString():Math.abs(Number(a)).toFixed(e);a=Number(c)-d.length-f.length;0<=b.indexOf("-",0)?d=f+d+na(" ",a):(b=0<=b.indexOf("0",0)?"0":" ",d=f+na(b,a)+d);return d};
$CLJS.ha.vc.d=function(a,b,c,d,e,f,h,l){return $CLJS.ha.vc.f(parseInt(a,10),b,c,d,0,f,h,l)};$CLJS.ha.vc.i=$CLJS.ha.vc.d;$CLJS.ha.vc.u=$CLJS.ha.vc.d;da("Opera");da("Trident")||da("MSIE");da("Edge");!da("Gecko")||-1!=ba().toLowerCase().indexOf("webkit")&&!da("Edge")||da("Trident")||da("MSIE")||da("Edge");-1!=ba().toLowerCase().indexOf("webkit")&&!da("Edge")&&da("Mobile");da("Macintosh");da("Windows");da("Linux")||da("CrOS");var sa=$CLJS.aa.navigator||null;sa&&(sa.appVersion||"").indexOf("X11");da("Android");ia();da("iPad");da("iPod");ja();ba().toLowerCase().indexOf("kaios");Date.now();ea();ia()||da("iPod");da("iPad");!da("Android")||fa()||ea()||da("Opera")||da("Silk");fa();!da("Safari")||fa()||da("Coast")||da("Opera")||da("Edge")||da("Edg/")||da("OPR")||ea()||da("Silk")||da("Android")||ja();